import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Layout,
  Image,
  Input,
  Row,
  Avatar,
  Popover,
  Button,
  Typography,
  Col,
  Modal,
  Form,
  message,
  theme,
  notification,
  Divider,
  Space
} from 'antd'
import CollapseImg from '../images/nav-bar-icon.svg'
import newLogoImg from '../images/cit-logo.svg'
import newLogosmImg from '../images/cit-favicon.svg'
import CitLogo from '../images/citlogo.svg'
import ArrowRight from '../images/ArrowRight.svg'
import notificationImg from "../images/notification‫Img.svg"
import more_vert from "../images/more_vert.svg";
import './MainLayout.css'
import Sidemenus from './Sidemenus'
import {
  LogoutOutlined,
  UserOutlined,
  SyncOutlined,
  SearchOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from '@ant-design/icons'
import { AuthService } from '../Services/AuthService'
import { useNavigate, useLocation } from 'react-router-dom'
import { useUser } from '../Context/UserContext'
import UnAuthRoute from './UnAuthRoute'
import ApplicationContext from '../Context/CreateApplicationContext'
import PageRoute from './PageRoute'
import UserImg from "../images/UserImg.svg"
const { Header, Sider, Content } = Layout

const MainLayout: React.FC = () => {
  const {
    isAuthenticated,
    isCandidate,
    isSuperAdmin,
    currentUser,
    getEmployeeId
  } = useUser()
  // const { getStudentOptions } = React.useContext(ApplicationContext);
  const userName: any = currentUser?.name.get()
  const userId: any = currentUser?.id.get()
  const employeeId: any = getEmployeeId()
  const [imagePath, setImagePath] = useState(null)

  const contentUser = (
    <div style={{ width: 295 }}>
      <Row justify='center' className='mt-3 mb-2'>
        {imagePath ? (
          <Avatar src={imagePath} className='avatar-user cursor' />
        ) : (
          <Avatar icon={<UserOutlined />} className='avatar-user cursor' />
        )}
      </Row>
      <Row justify='center'>
        <Typography.Title level={5} className='mb-2' style={{ margin: 0 }}>
          {userName}
        </Typography.Title>
      </Row>
      <Row justify='center' className='mb-2'>
        {currentUser?.email.get()}
      </Row>

      <Row
        className='py-2'
        style={{ paddingLeft: 9, paddingRight: 9 }}
        align='middle'
      >
        <Col className='cursor'>
          <Button
            type='text'
            size='small'
            className='linkcolor'
            icon={<SyncOutlined />}
            onClick={() => {
              setOpenChangePassword(true)
            }}
          >
            Change Password
          </Button>
        </Col>
      </Row>

      <Row
        className='py-2'
        style={{ paddingLeft: 9, paddingRight: 9 }}
        align='middle'
      >
        <Col className='cursor'>
          <Button
            type='text'
            size='small'
            className='linkcolor'
            icon={<LogoutOutlined rotate={-90} />}
            onClick={() => {
              UserLogOut()
            }}
          >
            Logout
          </Button>
        </Col>
      </Row>
    </div>
  )

  const navigate = useNavigate()
  const location = useLocation()
  const [form] = Form.useForm()
  const UserLogOut = () => {
    AuthService.logout().then(user => {
      navigate('/login')
      setImagePath(null)
    })
  }
  const [collapsed, setCollapsed] = useState(false)
  const style = {
    position: 'absolute',
    top: window.innerWidth <= 1250 ? '40px' : '65px',
    width: '255px',
    height: '100%',
    zIndex: '1'
  }
  const [width, setWidth] = useState(window.innerWidth)
  const [slide, setSlide] = useState(false)
  const [serachInput, setSearchInput] = useState(false)
  const [openChangePassword, setOpenChangePassword] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)

  const onCollapse = () => {
    if (window.innerWidth >= 1250) {
      setCollapsed(x => !x)
    }
    if (window.innerWidth <= 1250) {
      setSlide(x => !x)
    }
  }

  const handleResize = () => {
    setWidth(window.innerWidth)
    if (window.innerWidth < 1250) {
      setSlide(false)
    }
    if (window.innerWidth > 1250) {
      setSearchInput(false)
    }
  }
  const {
    token: { colorBgContainer }
  } = theme.useToken()
  const [openPopover, setOpenPopover] = useState(false)

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login')
    } else {
      // getStudentOptions();
    }
    window.addEventListener('resize', handleResize, false)
  }, [navigate])

  const logoStyle = {
    display: 'flex',
    justifyContent: collapsed ? 'center' : 'start',
    paddingLeft: collapsed ? '0px' : '20px',
    alignItems: 'center',
    height: '64px',
    marginBottom: '24px'
  }

  return (
    <React.Fragment>
      {isAuthenticated() ? (
        <Layout hasSider>
          {width >= 1250 && (
            <Sider
            className="sidebar-transition"
              trigger={null}
              collapsible
              collapsed={collapsed}
              style={{
                height: '100vh',
                position: 'fixed',
                // backgroundColor: '#F9F9FB',
                backgroundColor:"white",
            //  borderRight: '1px solid #D8D9E0', 
                // overflowY:"auto"
              }}
            >
              <Space align='center' style={{padding: collapsed? 25: 10}} >
                <img src={!collapsed? 'https://cit.capsitech.com/images/Citlogo.svg':CitLogo}/>
              </Space>
              {/* <div>
                <div style={{ position: 'fixed', zIndex: 1 }}>
                  <img
                    src={CitLogo}
                    // onClick={() => setCollapsed(!collapsed)}
                    style={{
                      padding: '22px 0px 0px 28px',
                      transitionDuration: '0.18s',
                      cursor: 'pointer'
                    }}
                    alt='img'
                  />
                </div>

                <img 
                  src={notificationImg}
                  alt='notification'
                  style={{ padding: '28px 0px 0px 189px',cursor:"pointer" }}
                />
              </div> */}
              <Button
                type='text'
                shape='circle'
                icon={
                  collapsed ? (
                    <img src={ArrowRight} />
                  ) : (
                    <img src={ArrowRight} />
                  )
                }
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: '16px',
                  position: collapsed ? 'fixed' : 'fixed',
                  width: 34,
                  height: 34,
                  left: collapsed ? 72 : 228,
                  top: 15,
                  transform: collapsed ? 'rotate(180deg)' : 'unset'
                }}
              />
              {/* <div
                style={{
                  marginTop: '20px',
                  padding: '7px 15px 0px 15px',
                  overflowY: 'auto',
                }}
              >
                {collapsed ? (
                  <>
                    <div style={{ display: 'flex', justifyContent: 'center', }}>
                      <img src={notificationImg} className='iconHover' />
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '12px',
                        cursor:'pointer',
                      }}
                    >
                      <SearchOutlined
                        style={{ marginTop: '5px', marginBottom: '-10px' }}
                      />
                    </div>
                    <Divider />
                  </>
                ) : (
                  <Input
                    placeholder='Search'
                    prefix={<SearchOutlined />}
                  // style={{ marginBottom: '7px' }}
                  />
                )}
              </div> */}
              <div
                style={{
                  padding: collapsed ? '0px 4px 0px 4px' : '0px 15px 0px 15px',
                  marginTop: collapsed ? '-38px' : ''
                }}
              >
                <Sidemenus
                
                  isCollapse={collapsed}
                  onsetSlide={setSlide}
                  styles={undefined}
                  isSlide={undefined}
                />

              </div>
              <div>
                <div style={{ bottom: 0,  position: "fixed", width: collapsed ? "90px" : "242px", transition: ' all 0.35s', padding: 10 }}
                >
                  <Popover
                    placement='bottomRight'
                    content={contentUser}
                    trigger='click'
                    open={openPopover}
                    onOpenChange={(val: boolean) => {
                      setOpenPopover(val)
                    }}
                  >
                    <Row gutter={[24,16]}>
                      <Col lg={5}>
                        <Avatar
                          src={UserImg}
                          icon={<UserOutlined />}
                          className='avatar-user'
                          style={{ marginLeft: collapsed? "18px" : "15px" }}
                          onClick={() => {
                            setOpenPopover(true)
                          }}
                        />
                      </Col>
                      {collapsed !== true ?
                        <Col span={20} style={{  paddingLeft: "11px" }}>

                          <h4 style={{ color: "#000000" }}> {userName}</h4>
                          {/* <h5 style={{color:"#000000",opacity:"50%",fontWeight:400,fontSize:"12px"}}>{currentUser?.email.get()}</h5> */}

                        </Col>
                        : ""
                      }
                      <Col span={1} style={{ textAlign: "center" }}>
                        {collapsed !== true ?

                          <img src={more_vert} /> : ""
                        }
                      </Col>
                    </Row>
                  </Popover>
                </div>
              </div>
            </Sider>
          )}
          <Layout
            style={
              collapsed
                ? { marginLeft: '90px', height: '100vh' }
                : {
                  height: '100vh',
                  marginLeft: '242px'
                }
            }
          >
             <Header
              //  className="ttp-layout"
              style={{
                // padding: 0,
                padding: '15px 20px',
                // borderBottom: '1px solid #D8D9E0', 
                backgroundColor: 'white',
                position: 'sticky',
                // position:'fixed',
                display: 'flex',
                justifyContent: 'space-between',
                height: '64px',
                width: '100%'
              }}
            >
              
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingTop: '10px',
                  paddingLeft: '18px'
                }}
              >
                <Typography.Title style={{color:"#7B8CAB",fontSize:"14px",fontWeight:500}}>Welcome {userName}</Typography.Title>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* <Button type='primary'>
                  <SearchOutlined />
                  New
                </Button> */}
              </div>

              {/* <img src={ArrowRight} alt='arrow' style={{paddingTop:"5px"}}/> */}
             </Header> 
            {/* <Content
            style={{
              marginTop:"75px",
              height: `calc(100vh-75px)`,
              padding: 24,
              backgroundColor:"white",
              // background: colorBgContainer 
              // borderRadius: borderRadiusLG,
            }}
          >
            {<PageRoute />}
          </Content> */}
            {/* <Content
        style={{height: `calc(100vh-75px)`,backgroundColor:"white"}}>
       lorem500
        </Content> */}
            <Content
              className="content-transition"
              style={{
                // height: `(100%-64px)`,
                paddingTop: '15px',
                overflowY: 'auto',
                backgroundColor:"#f3f4fa",
                padding: 24,
              }}
            >
              {<PageRoute />}
            </Content>
          </Layout>
        </Layout>
      ) : (
        <Layout className='site-layout'>
          <Content>
            <UnAuthRoute />
          </Content>
        </Layout>
      )}
    </React.Fragment>
  )
}
export default MainLayout

